import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Zinscap = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Entwicklung und Prognose" showCalc={false} />
            <Article>
                <p>
                    Die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    für Immobilienkredite liegen aktuell auf einem historisch niedrigen Niveau. Weil das nicht immer so
                    bleiben kann, suchen zahlreiche zukünftige Kreditnehmer:innen zur Zeit nach Möglichkeiten, das
                    Risiko eines durch Anpassungen des{" "}
                    <Link to="/artikel/leitzins/" target="_blank" rel="noreferrer noopener">
                        Leitzinses
                    </Link>{" "}
                    bedingten Kostenanstiegs aufgrund von einzuschränken. Eine Option bieten Zinscaps. Was das ist und
                    wie Zinscaps dir bei der Eindämmung deiner Finanzierungskosten helfen können, erfährst du im
                    folgenden Beitrag.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist ein Zinscap?</h2>
                <p>
                    Unter Zinscaps (oder Zinscap-Optionsschein) versteht man eine für einen bestimmten zeitlichen Rahmen
                    festgelegte Zinsobergrenze. Wird diese in einer Periode der Zinsanpassung überschritten, dann
                    erhältst du an deren Ende eine Ausgleichszahlung. Die Bank ist dazu verpflichtet, dir die Differenz
                    zwischen Obergrenze und tatsächlichem Zinsniveau zurückzuerstatten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Welche Arten von Zinscaps gibt es?</h2>
                <p>Beim Erwerb eines Zinscaps hast du mehrere Optionen:</p>
                <ul>
                    <li>
                        Bei <strong>endfälligen</strong> Zinscaps bleibt die besicherte Summe über die Laufzeit hinweg
                        konstant
                    </li>
                    <li>
                        <strong>Tilgend:</strong> Der besicherte Betrag verringert sich mit Ende der Laufzeit auf Null
                    </li>
                    <li>
                        <strong>Teiltilgend:</strong> Am Ende der LAufzeit bleibt ein Restbetrag
                    </li>
                    <li>
                        <strong>Forward Start:</strong> Der Beginn des Zinscaps und der Beginn der Laufzeit fallen nicht
                        zusammen. Der Zinscap tritt später in Kraft.
                    </li>
                </ul>
                <hr />

                <h2>Wann ist ein Zinscap empfehlenswert?</h2>
                <p>
                    Wirklich empfehlenswert ist ein Zinscap erst ab einem{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    von 50.000 Euro und nur dann, wenn bereits absehbar ist, dass es während der Laufzeit mit hoher
                    Wahrscheinlichkeit zu einer Anpassung des Zinssatzes nach oben kommen wird. Je stärker der
                    voraussichtliche Anstieg der Zinsen auf dem Finanzmarkt, umso höher ist auch die Wahrscheinlichkeit,
                    Ausgleichszahlungen von der Bank zu erhalten. Für Kredite geringen Umfangs ist ein Zinscap hingegen
                    wenig sinnvoll – als Kreditnehmer:in zahlst du hier nur drauf.
                </p>
                <p>
                    Ein kleiner Tipp am Rande: Solltest du dir noch nicht sicher sein, wie dein optimaler Kredit
                    aussehen soll, dann ist unser{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    genau das richtige für dich. Hier kannst du dich ganz einfach selbst mit den verschiedenen Größen
                    deines Kredits vertraut machen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Worauf muss ich beim Erwerb von Zinscaps achten?</h2>
                <p>
                    Beim Erwerb eines Zinscap ist eine Prämie im Ausmaß von 4-6 % des Kreditbetrags fällig. Allein
                    deswegen solltest du dir überlegen, ob diese Option für dich tatsächlich sinnvoll ist oder nicht
                    vielmehr zu leicht vermeidbaren Nebenkosten führt. Stelle also in jedem Fall sicher, dass die Kosten
                    für deine Absicherung unter der zu erwartenden Zinserhöhung liegen und überlege, wie hoch die Zinsen
                    sein müssen, damit ein Zinscap für dich überhaupt rentabel ist. Die Kosten lassen sich zudem durch
                    die Einschränkung der Laufzeit von Zinscaps veringern: Gegen Ende der Laufzeit deines Kredits wird
                    das durch Zinsen bedingte Risiko ohnehin geringer, was Zinscaps hier in nicht selten überflüssig
                    macht.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"zinscap"}></BreadcrumbList>
            <ArticleStructuredData page={"zinscap"} heading={"Entwicklung und Prognose"} />
        </Layout>
    );
};

export default Zinscap;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.zinscap", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
